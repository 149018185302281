import axios from 'axios'

let domain = process.env.VUE_APP_DOMAIN
let dev = process.env.VUE_APP_DEV
let server = process.env.VUE_APP_SERVER
let location = window.location.href
let v_dev = process.env.VUE_APP_VERSION_DEV
let v_server = process.env.VUE_APP_VERSION_SERVER

let baseUrl = ''

if (location.indexOf(domain) > -1) {
  baseUrl = server + v_server
} else {
  baseUrl = dev + v_dev
}

export default Object.freeze({
  install(Vue) {
    Vue.prototype.$Axios = async function ({method, url, data, header, authorize, multipart, TOKEN, blob, sample}) {

      const instance = axios.create({
        baseURL: baseUrl
        , timeout: 30000
      })

      const getUrl = function () {
        return url
      }

      const getParams = function () {
        if (method == 'get') {
          return data
        }
      }

      const getData = function () {
        if (method != 'get') {
          let formData = ''
          if (multipart) {
            formData = new FormData();
          } else {
            formData = new URLSearchParams();
          }

          for (let key in data) {
            if (Array.isArray(data[key])) {

              for (let i = 0; i < data[key].length; i++) {

                if (Array.isArray(data[key][i])) {
                  for (let sub_key in data[key][i]) {
                    formData.append(`${key}[` + i + '].' + sub_key, transVal(data[key][i][sub_key]))
                  }
                } else {
                  formData.append(`${key}`, transVal(data[key][i]))
                }
              }
            } else {
              formData.append(key, transVal(data[key]))
            }
          }

          return formData
        }
      }

      const transVal = function (value) {
        if (value === "" || value === null || value === undefined) {
          return ''
        } else {
          return value
        }
      }

      const getHeader = function () {

        let default_header = {}

        if (!authorize) {
          default_header.Authorization = 'Bearer ' + (TOKEN ? TOKEN : sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT'))
        }

        if (multipart) {
          default_header['Content-Type'] = 'multipart/form-data'
        }

        if (blob) {
          default_header['responseType'] = 'blob'
        }

        return header ? header : default_header
      }

      const getType = function () {
        console.log(sample)
        let type = ''
        if (sample) {
          type = 'blob'
        } else {
          type = ''
        }

        return type
      }

      try {
        const result = await instance({
          method: method
          , url: getUrl()
          , params: getParams()
          , data: getData()
          , headers: getHeader()
          , responseType: getType()
        })
        if (result.status == 200) {

          const data = result.data
          if (data.state == 200) {
            return {success: true, data: data.data, message: data.message}
          } else if (sample) {
            return {success: true, data: result, message: '다운로드'}
          } else if (result.status == 40120) {
            return {success: false, message: '인증이 만료되었습니다'}
          } else {
            return {success: false, message: data.message}
          }
        } else if (result.status == 400) {
          return {success: false, message: data.message}
        } else if (result.status == 401) {
          return result.data
        } else {
          const data = result.data

          if (data.state == 40120) {
            return {success: false, message: '인증이 만료되었습니다'}
          } else {

            return {success: false, message: '통신오류: ' + result.status}
          }
        }
      } catch (E) {
        if (E.response.data.error.length > 0) {
          E.response.data.message = E.response.data.error[0].message
        }
        if (E.response.status == 400) {
          return E.response.data
        } else if (E.response.status == 401) {
          document.location.href = '/Login'
        }
        return E.response.data
      }
    }
  }
})

