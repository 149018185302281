<template>
    <div
        class="full-height pa-10-20"
    >
        <div class="flex-row items-center justify-space-between">
            <div class="flex-row items-center">
                <h6>{{ program.name }}</h6>
                <button
                    class="pa-4-10 pr-3 ml-10 vertical-middle"
                    :class="is_immediately? 'bg-identify' : 'bg-identify-outline'"
                    @click="setCycle(true)"
                >D+0 <img v-if="immediate_error" src="../../assets/images/attention.svg" alt="attention">
                </button>
                <button
                    class="pa-4-10 ml-10 vertical-middle"
                    :class="!is_immediately? 'bg-identify' : 'bg-identify-outline'"
                    @click="setCycle(false)"
                >D+1 <img v-if="non_immediate_error" src="../../assets/images/attention.svg" alt="attention">
                </button>
            </div>
            <button
                @click="onRequest"
                class="box pa-4-10 bg-black color-white mr-10"
            >일괄 수동 요청
            </button>
        </div>
        <Search
            class="mt-10 box-ddd"
            :search="search"
            :option="search_option"

            @click="getSearch"
            @reset="reset"
            @setSearchDateType="setSearchDateType"
            @agency="setAgency"
        >
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.type"
                @change="getSearch(1)"
            >
                <option
                    v-for="add in codes.settlement_agency_type"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.code === '' ? '서비스' : add.name }}</option>
            </select>
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.state"
                @change="getSearch(1)"
            >
                <option
                    v-for="add in codes.payment_select_state_auto"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.name }}
                </option>
            </select>
        </Search>

        <div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">
            <div class="justify-space-between">

                <div
                >
                    <select
                        v-show="search.type == 1"
                        class="pa-5-10 mr-10 size-px-12"
                        v-model="item_confirm.state"
                        ref="settlement_status"
                    >
                        <option
                            v-for="(status, index) in codes.settlement_payment_state"
                            :key="'status_' + index"
                            :value="status.code"
                        >{{ status.name }}
                        </option>
                    </select>
                    <button
                        v-show="search.type == 1"
                        class="bg-identify pa-4-10 mr-10 vertical-middle"
                        @click="isConfirm"
                    >지급상태 변경
                    </button>
                </div>
                <div>
                    <button
                        class="box mr-10 pa-4-10 size-px-12"
                        @click="toExcel"
                    >
                        <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                        <span class="vertical-middle">엑셀 다운로드</span></button>
                    <select
                        class="pa-5-10 mr-10 size-px-12"
                        v-model="search.size"
                        @change="getSearch(1)"
                    >
                        <option
                            v-for="cnt in codes.list_cnt"
                            :key="'cnt_' + cnt"
                            :value="cnt"
                        >{{ cnt }} 건씩 보기
                        </option>
                    </select>
                </div>

            </div>
            <table
                v-if="items.length > 0"
                class="mt-10 table table-even top-line-identify"
            >
                <colgroup>

                    <col width="60px"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col v-if="!is_immediately" width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="100px"/>

                </colgroup>
                <thead>
                <tr>
                    <th>NO</th>
                    <th v-if="is_immediately">승인일시</th>
                    <th v-if="!is_immediately">매출 시작일</th>
                    <th v-if="!is_immediately">매출 종료일</th>
                    <th>요청 기준일</th>
                    <th>요청 완료일</th>

                    <th>가맹점명</th>
                    <th>서비스</th>
                    <th>은행</th>
                    <th>계좌번호</th>
                    <th>예금주명</th>

                    <th>입금자명</th>
                    <th>정산금액</th>
                    <th>요청상태</th>
                    <th>상세</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in item_list"
                    :key="'list_' + index"
                >
                    <td>{{ index + 1 }}</td>
                    <td v-if="is_immediately">{{ item.approvalDateTime? item.approvalDateTime.replaceAll('-', '.').replace('T', ' ') : '-' }}</td>
                    <td v-if="!is_immediately">{{ item.approvalStartDate? item.approvalStartDate.replaceAll('-', '.').split('T')[0] : '-' }}</td>
                    <td v-if="!is_immediately">{{ item.approvalEndDate? item.approvalEndDate.replaceAll('-', '.').split('T')[0] : '-' }}</td>
                    <td>
                        <span>{{ item.tradeDateTime? item.tradeDateTime.split('T')[0] : '-' }}</span>
                        <span v-if="item.tradeDateTime">({{ item.tradeDateTime | transWeek }})</span>
                        <span>{{ item.tradeDateTime? ' ' + item.tradeDateTime.split('T')[1] : '' }}</span>
                    </td>
                    <td>
                        <span>{{ item.depositDateTime? item.depositDateTime.split('T')[0] : '-' }}</span>
                        <span v-if="item.depositDateTime">({{ item.depositDateTime | transWeek }})</span>
                        <span>{{ item.depositDateTime? ' ' + item.depositDateTime.split('T')[1] : '' }}</span>
                    </td>
                    <td class="text-left">{{ item.shopName }}</td>
                    <td>{{ item.type_name }}</td>
                    <td>{{ item.bankName }}</td>
                    <td class="text-left">{{ item.accountNumber }}</td>
                    <td :class="{ 'text-left' : item.accountHolderName }">
                        <div v-if="item.accountHolderName">{{ item.accountHolderName }}</div>
                        <div v-else>-</div>
                    </td>
                    <td :class="{ 'text-left' : item.depositorName }">
                        <div v-if="item.depositorName">{{ item.depositorName }}</div>
                        <div v-else>-</div>
                    </td>
                    <td class="text-right color-identify font-weight-bold">{{ item.settlementAmount | makeComma }}원</td>
                    <td :class="'color-' + item.state_color" @click="showReason(item)">{{ item.state_name }}</td>
                    <td>
                        <button
                            class="bg-identify pa-5-10 size-px-12"
                            @click="toDetail(item)"
                        >상세보기
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                v-else
                class="full-height flex-column justify-center mt-10 top-line-identify"
            >
                <div class="text-center pa-20">
                    <v-icon
                        class="size-px-48 "
                    >mdi mdi-cloud-off-outline
                    </v-icon>
                    <br/>
                    <br/>
                    <div class="font-weight-bold size-px-24">No Data</div>
                </div>
            </div>


        </div>
        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"

            @click="getSearch"
        ></Pagination>

        <Excel
            v-if="is_immediately_excel"
            :excel_data="immediately_excel_data"

            @finish="close"
        ></Excel>
        <Excel
            v-if="is_non_immediately_excel"
            :excel_data="non_immediately_excel_data"

            @finish="close"
        ></Excel>
        <Modal
            :is_modal="is_modal2"
            :option="modal_option2"
            :top="true"

            title="가맹점 출금 처리결과 상세 내역"
            class="full-height"
            width="80%"
            height="80%"

            @close="close"

        >
            <SettlementAgencyListDetail
                :item="item"
                :type="search.type"
                :user="user"

                slot="modal-content"

                @setNotify="setNotify"
                @onLoading="$emit('onLoading')"
                @offLoading="$emit('offLoading')"
            ></SettlementAgencyListDetail>
        </Modal>
        <Modal
            :is_modal="is_on_request"
            :top="true"
            :bottom="true"

            title="일괄 수동 요청"
            content="요청 실패건을 일괄 수동 요청 하시겠습니까?"
            width="380px"
            content_class="ptb-30"

            @close="close"
            @cancel="close"
            @click="postRequest"
        ></Modal>
    </div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";

import SettlementAgencyListDetail from "@/view/Settlement/SettlementAgencyListDetail";

export default {
    name: 'shopAutoSettlement'
    , components: {SettlementAgencyListDetail, Modal, Excel, Search, Pagination}
    , data: function () {
        return {
            user: [],
            program: {
                name: '가맹점 출금 처리결과'
                , top: true
                , title: true
                , bottom: false
            }
            , search: {
                page: this.$route.query.page ? this.$route.query.page : 1
                , size: this.$route.query.size ? this.$route.query.size : 10
                , search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
                , search_value: ''
                , state: ''
                , type: ''
                , sDate: this.date.getToday('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
                ,total_count: 0
            }
            ,search_option: {
                sDate: true
                ,eDate: true
                ,search_type: [
                    {name: '아이디', column: 1}
                    , {name: 'MID', column: 2}
                    , {name: '가맹점명', column: 3}
                    , {name: '대표자명', column: 4}
                    , {name: '사업자번호', column: 5}
                ]
                ,is_search_date_type: true
                ,agency: false
                ,agency_sub: false
            }
            , is_immediately_excel: false
            , is_non_immediately_excel: false
            , immediately_excel_data: {
                name: '가맹점 출금 처리결과 내역'
                , header: [
                    {key: 0, name: '승인일시', column: 'approvalDateTime'}
                    , {key: 0, name: '요청 기준일', column: 'tradeDateTime'}
                    , {key: 0, name: '요청 완료일', column: 'depositDateTime'}
                    , {key: 0, name: '가맹점명', column: 'shopName'}
                    , {key: 0, name: '서비스', column: 'type'}
                    , {key: 0, name: '은행', column: 'bankName'}
                    , {key: 0, name: '계좌번호', column: 'accountNumber'}
                    , {key: 0, name: '예금주명', column: 'accountHolderName'}
                    , {key: 0, name: '입금자명', column: 'depositorName'}
                    , {key: 0, name: '정산금액', column: 'settlementAmount'}
                    , {key: 0, name: '요청상태', column: 'state'}

                ]
                , content: null
            }
            , non_immediately_excel_data: {
                name: '가맹점 출금 처리결과 내역'
                , header: [
                    {key: 0, name: '매출 시작일', column: 'approvalStartDate'}
                    , {key: 0, name: '매출 종료일', column: 'approvalEndDate'}
                    , {key: 0, name: '요청 기준일', column: 'tradeDateTime'}
                    , {key: 0, name: '요청 완료일', column: 'depositDateTime'}
                    , {key: 0, name: '가맹점명', column: 'shopName'}
                    , {key: 0, name: '서비스', column: 'type'}
                    , {key: 0, name: '은행', column: 'bankName'}
                    , {key: 0, name: '계좌번호', column: 'accountNumber'}
                    , {key: 0, name: '예금주명', column: 'accountHolderName'}
                    , {key: 0, name: '입금자명', column: 'depositorName'}
                    , {key: 0, name: '정산금액', column: 'settlementAmount'}
                    , {key: 0, name: '요청상태', column: 'state'}

                ]
                , content: null
            }
            , items: []
            , item: {}

            , item_payment: []
            , summary: {}
            , is_modal: false
            , modal_option: {}
            , is_modal3: false
            , modal_option3: {}
            , is_modal2: false
            , modal_option2: {}
            , is_all: false
            , item_confirm: {
                state: ''
                , approval: ''
                , idx: []
            }
            , settlementIds: []
            , is_immediately: true
            , immediate_error: false
            , non_immediate_error: false
            , is_on_request: false
        }
    }
    , computed: {
        item_list: function () {
            let self = this
            return this.items.filter(function (item) {

                for (let i = 0; i < self.codes.auto_state.length; i++) {
                    if (item.state === self.codes.auto_state[i].code) {
                        item.state_name = self.codes.auto_state[i].name
                        item.state_color = self.codes.auto_state[i].color
                        break
                    }
                }
                for (let i = 0; i < self.codes.settlement_agency_type.length; i++) {
                    if (item.type === self.codes.settlement_agency_type[i].code) {
                        item.type_name = self.codes.settlement_agency_type[i].name
                        break
                    }
                }

                return item
            })
        }
    }
    , methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                await this.getError()
                this.$set(this.search, 'startDate', this.search.sDate)
                this.$set(this.search, 'endDate', this.search.eDate)

                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }

                let urlType
                if(this.is_immediately) {
                    urlType = 'immediately'
                } else {
                    urlType = 'nonImmediately'
                }
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/shop/auto/' + urlType
                    , data: this.search
                })

                if (result.success) {
                    console.log(result.data.data)
                    this.items = result.data.data
                    this.search.total_count = result.data.totalCount
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

                const result2 = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/branch/statistics'
                    , data: this.search
                })

                if (result2.success) {
                    console.log(result2.data, 'list')
                    this.summary = result2.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: result2.message})
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , showReason: function (item) {
            if(item.reason) {
                this.$layout.setNotify({type: 'error', message: item.reason})
            }
        }
        , getError: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/shop/auto/error'
                })

                if (result.success) {
                    console.log(result.data, 'get error')
                    this.immediate_error = result.data.hasFailImmediatelySettlements
                    this.non_immediate_error = result.data.hasFailNonImmediatelySettlements
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , toDetail: function (item) {
            console.log(item,'to detail')
            let query = {
                keyword: item.shopName,
                sDate: this.is_immediately ? item.approvalDateTime.split('T')[0] : item.approvalStartDate.split('T')[0],
                eDate: this.is_immediately ? item.approvalDateTime.split('T')[0] : item.approvalEndDate.split('T')[0],
                search_date_type: 'free',
            }
            console.log(query)
            query = new URLSearchParams(query).toString()

            if(item.type === 'QUICK'){
                this.$tabs.open('/Quick/List?search_type=3&' + query, false, true)
            } else if(item.type === 'MANUAL'){
                this.$tabs.open('/Payment/List?search_type=5&' + query, false, true)
            } else if(item.type === 'MONTHLY'){
                this.$tabs.open('/Monthly/Payment/List?search_type=1&' + query, false, true)
            }

        }
        , reset: function () {
            this.search = {
                page: 1
                , size: 10
                , search_type: ''
                , keyword: ''
                , search_value: ''
                , state: ''
                , type: ''
                , cycle: ''
                , sDate: this.date.getToday('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
                ,total_count: 0
            }

            this.getData()
        }
        , onRequest: function () {
            this.is_on_request = true
        }

        , setCycle: function (type) {
            console.log(type)
            this.is_immediately = type
            this.getSearch()
        }
        , getSearch: function (page) {

            if (page) {
                this.search.page = page
            }

            this.getData()
        }

        ,toExcel: async function(){
            try{
                this.$layout.onLoading()
                let urlType
                if(this.is_immediately) {
                    urlType = 'immediately'
                } else {
                    urlType = 'nonImmediately'
                }
                const result = await this.$Axios({
                    method: 'get'
                    ,url: `calculate/shop/auto/${urlType}/excel`
                    ,data: this.search
                })

                if(result.success){
                    let excelItem = result.data
                    excelItem.forEach((item) => {
                        for (let i = 0; i < this.codes.auto_state.length; i++) {
                            if (item.state === this.codes.auto_state[i].code) {
                                item.state = this.codes.auto_state[i].name
                            }
                        }
                        for (let i = 0; i < this.codes.settlement_agency_type.length; i++) {
                            if (item.type === this.codes.settlement_agency_type[i].code) {
                                item.type = this.codes.settlement_agency_type[i].name
                            }
                        }
                    })
                    if(this.is_immediately) {
                        this.immediately_excel_data.content = excelItem
                        this.is_immediately_excel = true
                    } else {
                        this.non_immediately_excel_data.content = excelItem
                        this.is_non_immediately_excel = true
                    }
                }else{
                    this.$layout.setNotify( { type: 'error', message: result.message})
                }
            }catch(e){
                this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$layout.offLoading()
            }
        }
        , setSearchDateType: function (type) {
            this.search.search_date_type = type
        }
        , close: function () {
            this.is_modal = false
            this.is_modal2 = false
            this.is_modal3 = false
            this.is_excel = false
            this.is_on_request = false
        }
        , cancel: async function () {

        }
        , postRequest: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'put'
                    , url: 'calculate/shop/auto'
                })

                if (result.success) {
                    console.log(this.$tabs.items, 'quick transfer')
                    this.$tabs.refresh(null, true, false)
                    this.$layout.setNotify( {type: 'success', message: '일괄 수동 요청을 완료하였습니다.'})
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.close()
                this.$layout.offLoading()
            }
        }
        , isConfirm: function () {
            if (this.select_items.length <= 0) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.not_select})
                return
            }
            if (this.item_confirm.state === '') {
                this.$layout.setNotify( {type: 'error', message: '처리상태를 선택하세요'})
            } else {

                let status_name = ''

                for (let i = 0; i < this.codes.settlement_payment_state.length; i++) {
                    if (this.item_confirm.state == this.codes.settlement_payment_state[i].code) {
                        status_name = this.codes.settlement_payment_state[i].name
                    }
                }

                this.is_modal3 = true
                this.modal_option3.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?1'
            }
        }
        , doConfirm: async function () {
            try {
                this.$layout.onLoading()
                let settlementIdsList = []
                for (let i = 0; i < this.select_items.length; i++) {
                    settlementIdsList = settlementIdsList.concat(this.select_items[i])
                }
                const result = await this.$Axios({
                    method: 'post'
                    , url: 'calculate/branch'
                    , data: {
                        settlementIds: settlementIdsList
                        , state: this.item_confirm.state
                        , reason: this.item_confirm.reason
                    }
                })

                if (result.success) {
                    this.getSearch()
                    this.$layout.setNotify( {type: 'success', message: this.$language.common.success})
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

                this.is_all = false
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.close()
                this.$layout.offLoading()
            }
        }
        , setAgency: function (agency) {
            this.search.branchIdx = agency.branchList
            this.search.distributorIdx = agency.distributorList
            this.search.agencyIdx = agency.agencyList
            this.search.resellerIdx = agency.resellerList
            this.getSearch(this.search.page)
        }
        , setNotify: function ({type, message}) {
            this.$layout.setNotify( {type: type, message: message})
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
        this.getData()
    }
    , watch: {
        is_all: {
            handler: function (call) {
                let self = this
                this.items.filter(function (item) {
                    self.$set(item, 'is_select', call)
                })
            }
        }
        ,'search.search_date_type': {
            immediate: true
            , handler: function(call){
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
                let today = this.date.getToday('-')
                this.search.eDate = today
                switch(call){
                    case 'today':
                        this.search.sDate = today

                        this.getData()
                        break
                    case 'weekly':
                        this.search.sDate = this.date.getLastDate(today, 7, '-')

                        this.getData()
                        break
                    case 'month':
                        this.search.sDate = this.date.getLastDate(today, 30, '-')

                        this.getData()
                        break
                    case '3':
                        this.search.sDate = this.date.getLastDate(today, 90, '-')
                        break
                    case '6':
                        this.search.sDate = this.date.getLastDate(today, 180, '-')
                        break
                }
            }
        }
    }
}
</script>

<style>
.bg-icon-paper {
    background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calculator {
    background: url('../../assets/images/icon/icon-calculator.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-round-count {
    background: url('../../assets/images/icon/icon-round-count.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calc {
    background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center;
    padding-left: 70px;
}
</style>