<template>
    <div class="full-height pa-10-20 overflow-y-auto">
        <h6>{{ program.name }}</h6>

        <div>
            <div class="justify-space-between">
                <div class="flex-1 bg-white mr-10">
                    <div class="pa-10">
                        <h6 class="under-line-identify">신청 구분</h6>
                        <table class="table th-left td-left">
                            <col width="120px">
                            <col width="auto">
                            <col width="120px">
                            <col width="auto">
                            <tbody>
                            <tr>
                                <th>소속 영업단 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <div class="justify-start">

                                        <div
                                            class="flex-column justify-center pa-5-10 box mr-10 bg-eee font-weight-bold">
                                            {{ item.upper_name ? item.upper_name : upper_name }}
                                        </div>

                                        <button
                                            v-if="is_confirm"
                                            class="bg-identify pa-5-10 vertical-middle"
                                            @click="upperIdx = ''; getAgencyList()"
                                        >영업단 변경
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>아이디</th>
                                <td>{{ item.id }}</td>
                                <th>비밀번호</th>
                                <td class="text-right">
                                    <div class="justify-space-between">
                                        <span class=""></span>
                                        <button
                                            class="bg-identify pa-5-10"
                                            @click="isPassword"
                                        >임시 비밀번호 발급
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>심사여부</th>
                                <td>
                                    <div :class="'color-' + state_name.confirm.color">{{
                                            state_name.confirm.name
                                        }}
                                    </div>
                                </td>
                                <th>계정상태</th>
                                <td>
                                    <div
                                        class="justify-space-between"
                                    >
                                        <div class="flex-column justify-center"
                                             :class="'color-' + state_name.state.color">{{ state_name.state.name }}
                                        </div>
                                        <button
                                            v-if="item.confirm"
                                            class="bg-identify pa-5-10 "
                                            @click="isStatus"
                                        >계정 상태 변경
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>나이스 인증</th>
                                <td>
                                    <div :class="'color-' + state_name.nice.color">{{ state_name.nice.name }}</div>
                                </td>
                                <th>등록일자</th>
                                <td>{{ item.date }}</td>
                            </tr>


                            <tr>
                                <th>수기결제 MID</th>
                                <td class="">
                                    <input
                                        v-model="item.manualPaymentId"
                                        class="input-box full-width"
                                        placeholder="수기결제 MID"
                                        max="50"
                                    /></td>

                                <th>수기결제 MID KEY</th>
                                <td class="">
                                    <input
                                        v-model="item.manualPaymentPassword"
                                        type="password"
                                        class="input-box full-width"
                                        placeholder="수기결제 MID KEY"
                                        max="50"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th>PG사</th>
                                <td class="">
                                    <select
                                        v-model="item.manualPaymentVanType"
                                        class="input-box pa-5-10 "
                                    >
                                        <option value="">선택하세요</option>
                                        <template
                                            v-for="(type, index) in codes.manual_type"
                                        >
                                            <option
                                                :key="'type_' + index"
                                                :value="type.code"
                                            >{{ type.name }}
                                            </option>
                                        </template>
                                    </select>
                                </td>

                                <th>결제방식 <span class="color-red">*</span></th>
                                <td class="">
                                    <select
                                        v-model="item.canNonVerifyManualPayment "
                                        class="input-box pa-5-10 "
                                    >
                                        <option value="">선택하세요</option>
                                        <template
                                            v-for="(type, index) in codes.manual_payment_type "
                                        >
                                            <option
                                                :key="'type_' + index"
                                                :value="type.code"
                                            >{{ type.name }}
                                            </option>
                                        </template>
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-1 bg-white">
                    <div class="pa-10">
                        <h6 class=" under-line-identify">대표자 정보</h6>

                        <table class="table th-left td-left">

                            <col width="120px"/>
                            <col width="width-50-120"/>
                            <col width="120px"/>
                            <col width="width-50-120"/>

                            <tbody>
                            <tr>
                                <th>대표자명 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.name"
                                        class="input-box pa-5-10 mr-10"
                                        placeholder="대표자명을 입력하세요"
                                    />
                                </td>
                                <th>휴대폰 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.hp"
                                        class="input-box pa-5-10 mr-10"
                                        placeholder="대표자 휴대폰번호를 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>자택주소 <span class="color-red">*</span></th>
                                <td colspan="3">

                                    <input
                                        v-model="item.homeZipCode"

                                        class="input-box-inline mr-10"
                                        type="text" placeholder="우편번호"
                                        readonly

                                        @click="daumPost('home')"
                                    >

                                    <button
                                        class="box pa-5-10 bg-identify"
                                        @click="daumPost('home')"
                                    >주소 검색
                                    </button>

                                    <input
                                        v-model="item.homeAddress"

                                        class="input-box  mt-10" type="text" placeholder="주소"
                                        readonly

                                        @click="daumPost('home')"
                                    >

                                    <input
                                        v-model="item.homeAddressDetail"

                                        class="input-box  mt-10" type="text" placeholder="상세주소를 입력하세요."
                                        maxlength="50"
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="mt-10 justify-space-between">
                <div class="flex-1 mr-10 bg-white">
                    <div class="pa-10">
                        <div
                            class="under-line-identify justify-space-between"
                        >
                            <h6>서비스 이용 조건</h6>
                            <span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
                        </div>

                        <table class="table th-left td-left">
                            <col width="120px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr>
                                <th>서비스 수수료 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-1">
                                            배달비
                                            <input
                                                v-model="item.quickFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('QUICK')"
                                                :rules="[rules.demical(item, 'quickFee', { min: 2, max: 2})]"/> %
                                        </div>
                                        <div class="flex-1">
                                            월세
                                            <input
                                                v-model="item.monthlyFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('MONTHLY')"
                                                :rules="[rules.demical(item, 'monthlyFee', { min: 2, max: 2})]"/> %
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>수기결제 정산 주기 <span class="color-red">*</span></th>
                                <td>
                                    <input type="radio" id="0" :value="false" v-model="isInstant">
                                    <label for="0" class="ml-5 mr-20">
                                        D-Day 정산(0일 이후 정산)
                                    </label>
                                    <input type="radio" id="1" :value="true" v-model="isInstant">
                                    <label for="1" class="ml-5">
                                        즉시 정산
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>수기·월세 정산 방식 <span class="color-red">*</span></th>
                                <td>
                                    <input type="radio" id="auto" :value="true" v-model="item.autoSettlementFlag">
                                    <label for="auto" class="ml-5 mr-20">
                                        자동 정산
                                    </label>
                                    <input type="radio" id="manual" :value="false" v-model="item.autoSettlementFlag" :disabled="isInstant">
                                    <label for="manual" class="ml-5">
                                        수기 정산
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>수기결제 수수료 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-1" v-if="!isInstant">
                                            영업일 기준
                                            <select
                                                v-model="item.settlementCycle"
                                                class="pa-5-10 "
                                            >
                                                <option value="">선택</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                            </select>
                                            일 뒤
                                            <input
                                                v-model="item.nonInstantFee"
                                                class="input-box-inline width-50px text-right"
                                                @change="checkFee('D_DAY')"
                                                :rules="[rules.demical(item, 'nonInstantFee', { min: 2, max: 2})]"/> %를
                                            제외한 매출 정산
                                        </div>
                                        <div class="flex-1" v-else>
                                            즉시 정산
                                            <input
                                                v-model="item.instantFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('D_0')"
                                                :rules="[rules.demical(item, 'instantFee', { min: 2, max: 2})]"/> %
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>한도 설정</th>
                                <td>
                                    1회 한도
                                    <input
                                        type="number"
                                        v-model="item.oneLimit"
                                        placeholder="입력"
                                        class="input-box-inline text-right"
                                        :rules="[rules.max(item, 'limit_first', 5)]"
                                    /> 만원

                                    <div class="mt-10 mb-10">
                                        월한도
                                        <input
                                            type="number"
                                            v-model="item.monthlyLimit"
                                            placeholder="입력"
                                            class="input-box-inline text-right"
                                            :rules="[rules.max(item, 'limit_month', 6)]"
                                        /> 만원
                                    </div>

                                    연 한도
                                    <input
                                        type="number"
                                        v-model="item.yearlyLimit"
                                        placeholder="입력"
                                        class="input-box-inline text-right"
                                        :rules="[rules.max(item, 'limit_year', 7)]"
                                    /> 만원
                                </td>
                            </tr>
                            <tr>
                                <th>최대 할부 기간 <span class="color-red">*</span></th>
                                <td>
                                    <select
                                        v-model="item.installmentLimit"
                                        class="pa-5-10 "
                                    >
                                        <option value="">선택</option>
                                        <option value="0">일시불</option>
                                        <option v-for="(i, index) in 11" :key="index" :value="++i">{{ i++ }}</option>
                                    </select> 개월
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-1 bg-white">

                    <div class="pa-10">

                        <h6 class="under-line-identify">사업자 정보</h6>
                        <table class="table th-left td-left">
                            <col width="120px">
                            <col width="width-50-120">
                            <col width="120px">
                            <col width="width-50-120">
                            <tbody>
                            <tr>
                                <th>사업자 구분 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <label
                                        class="cont-radio"
                                        v-for="code in codes.P02"
                                        :key="code.TOTAL_CODE"
                                    >
                                        <input
                                            v-model="item.businessType"
                                            type="radio"
                                            :value="code.TOTAL_CODE"
                                            class="box"
                                        />
                                        {{ code.code_name }}
                                    </label>
                                </td>
                            </tr>
                            <tr v-if="Number(item.businessType) !== 2">
                                <th>생년월일 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input type="date" v-model="item.birthDate">
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '1'"
                            >
                                <th>사업자등록번호 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        type="number"
                                        v-model="item.businessNumber"
                                        :ruels="[rules.max(item, 'businessNumber', 15)]"
                                        class="input-box-inline"
                                        placeholder="사업자 등록 번호"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '0'"
                            >
                                <th>사업자등록번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.businessNumber"
                                        :ruels="[rules.max(item, 'businessNumber', 15)]"
                                        class="input-box"
                                        placeholder="사업자 등록 번호"
                                    />
                                </td>
                                <th>법인등록번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.corporationNumber"
                                        :ruels="[rules.max(item, 'corporationNumber', 15)]"
                                        class="input-box"
                                        placeholder="법인 등록 번호"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '2'"
                            >
                                <th>주민등록 번호 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        v-model="item.frontSSN"
                                        type="number"
                                        :ruels="[rules.max(item, 'frontSSN', 6)]"
                                        class="input-box-inline mr-10"
                                        placeholder="주민등록 앞번호"
                                    />
                                    <input
                                        v-model="item.backSSN"
                                        type="password"
                                        maxlength="7"
                                        class="input-box-inline"
                                        placeholder="주민등록 뒷번호"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>업태 <span class="color-red">*</span></th>
                                <td class="si-desc"
                                    style="margin-right: 10px;">
                                    <input
                                        type="text"
                                        v-model="item.businessCondition"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="업태를 입력하세요"
                                    />
                                </td>
                                <th>업종 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.industry"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="업종을 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>상호명 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        type="text"
                                        v-model="item.shopName"
                                        maxlength="50"
                                        class="input-box"
                                        placeholder="상호명을 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>유선 전화</th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.linePhone"
                                        :ruels="[rules.max(item, 'linePhone', 12)]"
                                        class="input-box full-width"
                                        placeholder="유선전화번호를 입력하세요"
                                    />
                                </td>
                                <th>팩스 번호</th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.fax"
                                        :ruels="[rules.max(item, 'fax', 12)]"
                                        class="input-box full-width"
                                        placeholder="팩스번호를 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>사업장 주소 <span class="color-red">*</span></th>
                                <td colspan="3">

                                    <input
                                        v-model="item.zipCode"

                                        class="input-box-inline mr-10"
                                        type="text" placeholder="우편번호"
                                        readonly

                                        @click="daumPost('company')"
                                    >

                                    <button
                                        class="box pa-5-10 bg-identify"
                                        @click="daumPost('company')"
                                    >주소 검색
                                    </button>

                                    <input
                                        v-model="item.address"

                                        class="input-box full-width mt-10" type="text" placeholder="주소"
                                        readonly

                                        @click="daumPost('company')"
                                    >
                                    <input
                                        v-model="item.addressDetail"

                                        class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
                                        maxlength="50"
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div class="mt-10 justify-space-between">
                <div class="flex-1 mr-10 bg-white">
                    <div class="pa-10">
                        <h6>정산 계좌 정보</h6>
                        <table class="table th-left td-left top-line-identify">
                            <col width="120px"/>
                            <col width="width-50-120"/>
                            <col width="120px"/>
                            <col width="width-50-120"/>
                            <tbody>
                            <tr>
                                <th>은행 <span class="color-red">*</span></th>
                                <td>
                                    <select
                                        v-model="item.calculateBankIdx"
                                        class="input-box"
                                    >
                                        <option value="">선택하세요</option>
                                        <option
                                            v-for="(code, index) in codes.P13"
                                            :key="'bank_' + index"
                                            :value="code.idx"
                                        >{{ code.name }}
                                        </option>
                                    </select>
                                </td>
                                <th>계좌번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.calculateAccount"
                                        type="number"
                                        class="input-box full-width"
                                        :rules="[rules.max(item, 'account', 25)]"
                                        placeholder="정산 계좌 번호"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>예금주 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.calculateAccountHolder"
                                        class="input-box-inline mr-10 full-width"
                                        placeholder="정산 계좌 예금주"
                                        maxlength="15"
                                    />
                                </td>
                                <th>이메일 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.email"
                                        maxlength="50"
                                        class="input-box"
                                        placeholder="세금계산서 발행 이메일을 입력하세요"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-1 bg-white">

                    <div class=" pa-10 ">
                        <h6>첨부파일</h6>
                        <table class="table th-left td-left full-width top-line-identify">
                            <col width="120px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr>
                                <th>계약서류 <span class="color-red">*</span></th>
                                <td>
                                    <div
                                        v-for="(file, index) in codes.merchant_files"
                                        :key="'file_' + index"

                                        class=" ptb-10"
                                    >
                                        <label class="box pa-5-10 mr-10 vertical-middle">
                                            <input
                                                v-show="false"
                                                type="file"
                                                accept="image/*"
                                                @change="setFile($event, file.code)"
                                            />
                                            <v-icon
                                                small
                                            >mdi mdi-image-multiple
                                            </v-icon>
                                            {{ file.name }}
                                        </label>

                                        <button
                                            v-if="item_file[file.code]"
                                            :key="'file_down_' + file.code"

                                            class="btn-default pa-5-10 vertical-middle"
                                            @click="download(item_file[file.code])"
                                        >{{ item_file[file.code].originFileName }}
                                            <v-icon small>mdi mdi-download</v-icon>
                                        </button>
<!--                                        <button-->
<!--                                            v-if="false"-->
<!--                                            :key="'file_delete_' + index"-->
<!--                                            @click="isDeleteFile('', index)"-->
<!--                                            class="box pa-5 "-->
<!--                                        >-->
<!--                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>-->
<!--                                        </button>-->
                                    </div>

                                    <div class="mt-10">
                                        <label class="box pa-5-10 vertical-middle">
                                            <input
                                                v-show="false"
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                @change="setFile($event, 'attachment')"
                                            />
                                            <v-icon
                                                small
                                            >mdi mdi-image-multiple
                                            </v-icon>
                                            첨부파일
                                        </label>
                                    </div>

                                    <div class="mt-10">
                                        <template
                                            v-for="(file, index) in files"
                                        >
                                            <button
                                                :key="'file_' + index"
                                                class="btn-default pa-5-10 mb-10"
                                                @click="download(file)"
                                            >{{ file.originFileName }}
                                                <v-icon small>mdi mdi-download</v-icon>
                                            </button>
                                            <button
                                                :key="'file_delete_' + index"
                                                @click="isDeleteFile('', index)"
                                                class="box pa-5  mb-10 mr-10"
                                            >
                                                <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                            </button>
                                        </template>

                                        <button
                                            v-for="(file, index) in item.attachments"
                                            :key="'file_new_' + index"
                                            @click="isDeleteFile('attachment', index)"
                                            class="btn-default pa-5-10 mr-10 mb-10"
                                        >{{ file.name }}
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="mtb-30 justify-center"
        >
            <button
                v-if="is_confirm_return"
                class="bg-identify pa-10-20 mr-10"
                @click="isConfirmReturn"
            >심사반려
            </button>
            <button
                v-if="is_confirm"
                class="bg-identify pa-10-20 mr-10"
                @click="isConfirm"
            >심사완료
            </button>
            <button
                v-if="is_save"
                class="bg-identify-outline pa-10-20 mr-10"
                @click="doConfirm"
            >저장
            </button>
            <button
                class="bg-green pa-10-20 mr-10"
                @click="is_modal_copy = true"
            >가맹점 복사
            </button>
            <button
                class="bg-ddd pa-10-20"
                @click="toList"
            >목록
            </button>
        </div>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"

            title="임시 비밀번호 발급"
            content="임시 비밀번호를 발급하고 등록된 연락처로 전송하시겠습니까?"
            :top="true"
            :bottom="true"
            width="360px"
            content_class="pa-30-10 text-left"

            @close="clear"
            @click="doPassword"
            @cancel="clear"
        >
        </Modal>
        <Modal
            :is_modal="is_modal2"
            :option="modal_option2"
            :top="true"
            :bottom="true"

            title="가맹점 계정상태 변경"
            width="360px"

            @close="clear"
            @click="doStatus"
            @cancel="clear"
        >

            <div
                slot="modal-content"
            >
                <select
                    v-model="item.state"
                    class="full-width pa-10"
                >
                    <option
                        v-for="(status, index) in codes.status_agency"
                        :key="'status_' + index"
                        :value="status.code"
                    >{{ status.name }}
                    </option>
                </select>

                <textarea
                    class="mt-10"
                    v-model="item.reason"
                    placeholder="메모"
                ></textarea>

            </div>
        </Modal>

        <Modal
            :is_modal="is_modal3"
            :option="modal_option3"
            :top="true"
            :bottom="true"

            title="첨부파일 삭제"
            content="해당 첨부파일을 삭제하시겠습니까?"
            width="360px"

            @close="clear"
            @click="doFileDelete"
            @cancel="clear"
        >
        </Modal>
        <Modal
            :is_modal="is_modal_copy"
            :option="copy_modal_option"
            :top="true"
            :bottom="true"

            title="가맹점 복사"
            content="가맹점 복사 시 신규 가맹점 등록으로 구분됩니다. 수정 후 꼭 저장을 클릭하세요."
            width="400px"

            @close="clear"
            @click="copy"
            @cancel="clear"
        >
        </Modal>

        <Modal
            :is_modal="is_modal7"
            :option="modal_option4"
            content_class="ptb-30"

            title="심사반려"
            :top="true"
            :bottom="true"
            content="해당 가맹점 등록심사를 심사반려로 처리하시겠습니까?"
            width="360px"

            @close="clear"
            @click="doConfirmReturn"
            @cancel="clear"
        >
        </Modal>

        <Modal
            :is_modal="is_modal4"
            :option="modal_option4"
            content_class="ptb-30"

            title="심사완료"
            :top="true"
            :bottom="true"
            content="가맹점 등록심사에 필요한 항목을 모두 확인하고 심사완료로 처리하시겠습니까?"
            width="360px"

            @close="clear"
            @click="doConfirm"
            @cancel="clear"
        >
        </Modal>

        <Modal
            :is_modal="is_modal5"
            :option="{}"
            :top="true"
            :bottom="false"

            title="첨부파일"

            @close="clear"
        >
            <div slot="modal-content">
                <img :src="item_att" style="object-fit: contain; width: 420px; height: 820px;" alt=""/>
            </div>
        </Modal>

        <Modal
            :is_modal="is_modal6"
            :option="{}"
            :top="true"
            title="소속 영업점 변경"

            @close="clear"
            height="850px"
        >
            <AgencySearchList
                 slot="modal-content"

                :item_type="99"
                :items="agency_list"
                :user="user"

                @click="setUpper"
                @cancel="upperIdx = item.upperIdx; clear()"
                @next="nextUpper"
            ></AgencySearchList>
        </Modal>

        <DaumPost
            :overlay="is_post"
            :config="daum_config"
            @callBack="addPost"
        ></DaumPost>
    </div>
</template>

<script>
import Modal from "@/components/Modal";
import DaumPost from "@/components/Daum/DaumPost";
import AgencySearchList from "@/view/Agency/AgencySearchList";

export default {
    name: 'AgencyDetail'
    ,
    components: {AgencySearchList, DaumPost, Modal},
    data: function () {
        return {
            user: [],
            program: {
                name: '가맹점 상세정보'
                , top: true
                , title: true
                , bottom: false
            }
            , fee_base: 4
            , item: {}
            , item_origin: {}
            , files: []
            , is_modal: false
            , modal_option: {}
            , is_modal2: false
            , modal_option2: {}
            , is_modal3: false
            , modal_option3: {}
            , is_modal4: false
            , is_modal5: false
            , is_modal6: false
            , is_modal7: false
            , is_modal_copy: false
            , copy_modal_option: {
                click_name: '복사'
            }
            , modal_option4: {}
            , is_post: false
            , daum_config: {
                width: '360px'
            }
            , deleteIdx: []
            , item_att: ''
            , item_file: {
                bossIdImg: {}
                , businessImg: {}
                , copyOfBankbook: {}
            }
            , agency_list: []
            , upperIdx: ''
            , fee2: 0
            , maxAvailableFee: 0
            , feeInfo: []
            , isInstant: false
            , shopFeeType: ['QUICK', 'MONTHLY', 'D_DAY', 'D_0']
        }
    }
    , computed: {
        routeTab: function () {
            return `${this.item.shopName}`
        },
        upper_name: function () {
            let name = this.item.branchName
            if (this.item.distributorIdx) {
                name += ' > ' + this.item.distributorName
            }
            if (this.item.agencyIdx) {
                name += ' > ' + this.item.agencyName
            }
            if (this.item.resellerIdx) {
                name += ' > ' + this.item.resellerName
            }

            return name
        }
        , state_name: function () {
            let state = {
                confirm: {name: '-', color: ''}
                , nice: {name: '-', color: ''}
                , state: {name: '-', color: ''}
            }
            for (let i = 0; i < this.codes.shop_state.length; i++) {
                if (this.item.state == this.codes.shop_state[i].code) {
                    state.state.name = this.codes.shop_state[i].name
                    state.state.color = this.codes.shop_state[i].color
                    break
                }
            }

            for (let i = 0; i < this.codes.shop_confirm.length; i++) {
                if (this.item.confirm == this.codes.shop_confirm[i].code) {
                    state.confirm.name = this.codes.shop_confirm[i].name
                    state.confirm.color = this.codes.shop_confirm[i].color
                    break
                }
            }

            for (let i = 0; i < this.codes.nice_confirm.length; i++) {
                if (this.item.nice == this.codes.nice_confirm[i].code) {
                    state.nice.name = this.codes.nice_confirm[i].name
                    state.nice.color = this.codes.nice_confirm[i].color
                    break
                }
            }

            return state
        }
        , is_confirm: function () {
            let is = false

            if (!this.item.confirm && this.user.roleCode == '0') {
                is = true
            }

            return is
        }
        , is_confirm_return: function () {
            let is = false

            if (!this.item.confirm && this.user.roleCode == '0') {
                is = true
            }

            return is
        }
        , is_save: function () {
            let is = false

            if (this.item.confirm && this.user.roleCode == '0') {
                is = true
            }

            return is
        }
    }
    , methods: {
        copy: function () {
            for (const [key, value] of Object.entries(this.item)) {
                if (value === 'null' || value === '' || value === null) {
                    this.item[key] = ''
                }
                if (typeof value === 'string') {
                    if (value.indexOf('undef') !== -1) {
                        this.item[key] = ''
                    }
                }
                if (key === 'nice'
                    || key === 'state'
                    || key === 'businessImg'
                    || key === 'bossIdImg'
                    || key === 'copyOfBankbook'
                ) {
                    delete this.item[key]
                }
                if (key === 'adminIdx'
                    || key === 'userIdx'
                    || key === 'id'
                    || key === 'manualPaymentId'
                    || key === 'manualPaymentPassword'
                    || key === 'backSSN'
                ) {
                    this.item[key] = ''
                }
            }
            console.log(this.item)
            let item = JSON.stringify(this.item)
            console.log(item)
            this.$router.push({name: 'MerchantItem',  params: { copyItem: item }})
            this.clear()
        }
        , getData: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'shop/' + this.$route.params.idx
                })
                if (result.success) {
                    this.item = result.data
                    this.item.manualPaymentVanType = this.item.manualPaymentVanType ? this.item.manualPaymentVanType : ''
                    this.isInstant = this.item.settlementCycle === 0 ? this.isInstant = true : this.isInstant = false
                    this.files = this.item.attachments
                    this.item_file.businessImg = this.item.businessImg
                    this.item_file.bossIdImg = this.item.bossIdImg
                    this.item_file.copyOfBankbook = this.item.copyOfBankbook
                    this.$delete(this.item, 'businessImg')
                    this.$delete(this.item, 'bossIdImg')
                    this.$delete(this.item, 'copyOfBankbook')
                    this.item.attachments = []
                    this.upperIdx = this.item.upperIdx
                    if (!this.item.fee) {
                        this.$set(this.item, 'fee', 0)
                        this.$set(this.item, 'fee2', 0)
                    }
                    for (const [key, value] of Object.entries(this.item)) {
                        if (value === 'null' || value === null) {
                            this.item[key] = ''
                        }
                    }
                    this.item_origin = result.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , isPassword: function () {
            this.clear()
            this.is_modal = true
        }
        , isStatus: function () {
            this.clear()
            this.is_modal2 = true
        }
        , clear: function () {
            this.is_modal = false
            this.is_modal2 = false
            this.is_modal3 = false
            this.is_modal4 = false
            this.is_modal5 = false
            this.is_modal6 = false
            this.is_modal7 = false
            this.is_modal_copy = false
        }
        , doStatus: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'shop/state/' + this.$route.params.idx
                    , data: {
                        state: this.item.state
                        , reason: this.item.reason
                    }
                })
                if (result.success) {
                    await this.getData()
                    this.$layout.setNotify( {type: 'success', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , doPassword: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'shop/tempPassword/' + this.$route.params.idx
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , toList: function () {
            this.$layout.push( {name: 'MerchantList', not_query: true})
        }

        , daumPost: function (type) {
            this.address_type = type
            this.is_post = true
        }
        , addPost: function (call) {

            if (this.address_type == 'home') {
                this.$set(this.item, 'homeZipCode', call.zonecode)
                this.$set(this.item, 'homeAddress', call.address)
            } else {
                this.$set(this.item, 'zipCode', call.zonecode)
                this.$set(this.item, 'address', call.address)
            }

            this.is_post = false
        }
        , isDeleteFile: function (type, index) {

            if (type == 'attachment') {
                this.$delete(this.item.attachments, index)
            } else {
                this.deleteIdx.push(this.files[index].idx)
                this.$set(this.item, 'deleteIdx', this.deleteIdx)
                this.$delete(this.files, index)
            }
        }
        , doFileDelete: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'post'
                    , url: ''
                    , data: this.item
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , download: function (file) {
            this.is_modal5 = true
            this.item_att = file.path
        }
        , getAgencyList: async function () {
            this.is_modal6 = true
        }
        , isConfirm: function () {
            this.is_modal4 = true
        }
        , isConfirmReturn: function () {
            this.is_modal7 = true
        }
        , doConfirmReturn: async function () {
            try {
                this.$layout.onLoading()


                if (this.item.businessType == this.codes.P02_0) {
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                } else if (this.item.businessType == this.codes.P02_1) {
                    this.item.corporationNumber = ''
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                } else if (this.item.businessType == this.codes.P02_2) {
                    this.item.businessNumber = ''
                    this.item.corporationNumber = ''
                }

                const result = await this.$Axios({
                    method: 'delete'
                    , url: 'shop/' + this.$route.params.idx
                    , data: this.item
                    , multipart: true
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    this.getData()
                } else {
                    if (result.message) {
                        this.$layout.setNotify( {type: 'error', message: result.message})
                    } else {
                        this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , trans_birthday: function (birth) {
            const code = Number(this.item.backSSN.slice(0,1))
            let dateOfBirth = ''

            if(code === 1 || code === 2 || code === 5 || code === 6){
                // 한국인 1900~, 외국인 1900~
                dateOfBirth = "19"+birth;
            }else if(code === 3 || code === 4 || code === 7 || code === 8){
                // 한국인 2000~, 외국인 2000~
                dateOfBirth = "20"+birth;
            }else if(code === 9 || code === 0){
                // 한국인 1800~
                dateOfBirth = "18"+birth;
            }
            return dateOfBirth.slice(0,4) + '-' + dateOfBirth.slice(4,6) + '-' + dateOfBirth.slice(6);
        }
        , doConfirm: async function () {
            console.log(this.item, 'item')
            try {
                this.$layout.onLoading()

                if(this.item) {
                    console.log(this.item.attachments)
                    console.log(this.item_file)
                    console.log(this.files)
                    console.log(this.deleteIdx,'deleteIdx')
                    // return
                }


                if (this.item.businessType == this.codes.P02_0) {
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                } else if (this.item.businessType == this.codes.P02_1) {
                    this.item.corporationNumber = ''
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                } else if (this.item.businessType == this.codes.P02_2) {
                    this.item.businessNumber = ''
                    this.item.corporationNumber = ''
                }
                for (const [key, value] of Object.entries(this.item)) {
                    if (value === 'null' || value === '' || value === null) {
                        delete this.item[key]
                    }
                    if (typeof value === 'string') {
                        if (value.indexOf('undef') !== -1) {
                            delete this.item[key]
                        }
                    }
                }

                if(Number(this.item.businessType) === 2) {
                    this.item.birthDate = this.trans_birthday(this.item.frontSSN)
                }
                if(this.isInstant) {
                    this.item.settlementCycle = 0
                }
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'shop/' + this.$route.params.idx
                    , data: this.item
                    , multipart: true
                })
                if (result.success) {
                    this.deleteIdx = []
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    console.log(this.deleteIdx,'deleteIdx11111111111111')
                    await this.getData()
                } else {
                    if (result.message) {
                        this.$layout.setNotify( {type: 'error', message: result.message})
                    } else {
                        this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , setFile: function (e, type) {
            console.log(e)
            console.log(type)

            if (type == 'attachment') {
                for (let i = 0; i < e.target.files.length; i++) {
                    this.item.attachments.push(e.target.files[i])
                }
            } else {
                for (let i = 0; i < this.codes.merchant_files.length; i++) {
                    if (type == this.codes.merchant_files[i].code) {
                        this.$set(this.item, this.codes.merchant_files[i].code, e.target.files[0])
                    }
                }
            }
        }

        , setUpper: function (item, item_list) {

            this.item.branchIdx = item_list.branchList.idx
            this.item.upper_name = item_list.branchList.name
            this.item.distributorIdx = ''
            this.item.agencyIdx = ''
            this.item.resellerIdx = ''
            this.item.subresellerIdx = ''

            if (item.type >= item_list.distributorList.type) {
                this.item.distributorIdx = item_list.distributorList.idx
                this.item.upper_name += ' > ' + item_list.distributorList.name
            }

            if (item.type >= item_list.agencyList.type) {
                this.item.agencyIdx = item_list.agencyList.idx
                this.item.upper_name += ' > ' + item_list.agencyList.name
            }

            if (item.type >= item_list.resellerList.type) {
                this.item.resellerIdx = item_list.resellerList.idx
                this.item.upper_name += ' > ' + item_list.resellerList.name
            }

            if (item.type >= item_list.subResellerList.type) {
                this.item.subresellerIdx = item_list.subResellerList.idx
                this.item.upper_name += ' > ' + item_list.subResellerList.name
            }

            this.upperIdx = item.idx
            this.clear()
        }
        , resetUpper: function () {
            this.upperIdx = ''
            this.item.upper_name = ''
        }
        , nextUpper: function (type, sub) {
            this.upperIdx = sub.idx
            this.getAgencyList(type)
        }
        , getFee: async function (type, index) {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'shop/fee'
                    , data: {
                        upperAdminIdx: this.upperIdx
                        , shopFeeType: type
                    }
                })
                if (result.success) {
                    this.feeInfo.push(result.data)
                    this.feeInfo[index].type = type
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , checkFee: function (type) {
            if(type === 'QUICK') {
                if(this.item.quickFee < this.feeInfo[0].minValue || this.item.quickFee > this.feeInfo[0].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `배달비 수수료는 최소 ${this.feeInfo[0].minValue}%, 최대${this.feeInfo[0].maxValue}% 입력 가능합니다.`
                    })
                    this.item.quickFee = this.feeInfo[0].minValue
                }
            }
            if(type === 'MONTHLY') {
                if(this.item.monthlyFee < this.feeInfo[1].minValue || this.item.monthlyFee > this.feeInfo[1].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `월세 수수료는 최소 ${this.feeInfo[1].minValue}%, 최대${this.feeInfo[1].maxValue}% 입력 가능합니다.`
                    })
                    this.item.monthlyFee = this.feeInfo[1].minValue
                }
            }
            if(type === 'D_DAY') {
                if(this.item.nonInstantFee < this.feeInfo[2].minValue || this.item.nonInstantFee > this.feeInfo[2].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `D-DAY 정산 수수료는 최소 ${this.feeInfo[2].minValue}%, 최대${this.feeInfo[2].maxValue}% 입력 가능합니다.`
                    })
                    this.item.nonInstantFee = this.feeInfo[2].minValue
                }
            }
            if(type === 'D_0') {
                if(this.item.instantFee < this.feeInfo[3].minValue || this.item.instantFee > this.feeInfo[3].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `즉시 정산 수수료는 최소 ${this.feeInfo[3].minValue}%, 최대${this.feeInfo[3].maxValue}% 입력 가능합니다.`
                    })
                    this.item.instantFee = this.feeInfo[3].minValue
                }
            }
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
        this.getData()
    }
    , watch: {
        upperIdx: {
            handler: function (call) {
                if (call) {
                    for (let i = 0; i < this.shopFeeType.length; i++) {
                        this.getFee(this.shopFeeType[i], i)
                    }
                }
            }
        }
        , 'isInstant':
            {
                handler: function () {
                    if(this.isInstant){
                        this.item.autoSettlementFlag = true
                    }
                }
            }
    }
}
</script>

<style>
.width-fee {
    width: 60px !important;
    text-align: right;
}
</style>